export default async ({ primary }, pageData, { $prismic }) => {
  const moduleId = primary?.quote_module?.id;
  if (!moduleId) return null;

  const { data } = await $prismic.client.getByID(moduleId);
  if (!data) return null;

  return {
    uiWrapperWidth: primary.ui_wrapper_width,
    background: data.background,
    image: data.image && {
      alt: data.image.name,
      filename: data.image.cdn_link,
    },
    text: data.text,
    author: data.author,
  };
};
